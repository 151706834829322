:root {
  --main-color: #72a300;
  --secondary-color: #046a38;
}

a {
  color: var(--secondary-color);
  font-weight: bold;
  text-decoration: none;
}

a img {
  border-color: var(--secondary-color);
  border-style: solid;
}

a:hover {
  color: var(--main-color);
}

a:hover img {
  border-color: var(--main-color);
  border-style: solid;
}

body {
  font-family: Palatino;
  margin: 0px;
  background-color: aliceblue;
}

h1 {
  background-color: lightgreen;
  margin: 0;
  padding: 2em;
  text-align: center;
}

h2 {
  font-size: 3rem;
  text-align: center;
}

h3 {
  margin-left: -1rem;
  font-size: 2rem;
}

h4 {
  font-size: 1.5rem;
}

section {
  border-top: dotted;
  margin: 2em;
}

img {
  max-height: 80vh;
  max-width: 80vw;
}