div.container {
    position: relative;
}

div.text {
    position: absolute;
    margin: auto;
    top: 105px;
    width: 100%;
}

div.text span {
    margin: auto;
    font-size: 4em;
}