header::before {
  content: "";
  background-color: #72A300;
  height: 6px;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
}

header {
  margin-top: 2em;
  padding: 0px;
  text-align: center;
}

nav {
  font-size: 120%;
  position: sticky;
  top: 0;
  background-color: lightgoldenrodyellow;
  margin: 0px;
}

nav ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  justify-items: center;
  text-align: center;
  margin: 0;
}

nav li {
  display: inline-block;
  list-style: none;
  line-height: 2em;
  padding: 1em;
}

section {
  margin-top: 2em;
  margin-bottom: 2em;
}

footer {
  background-color: lightgray;
  padding: 6px;
  text-align: center;
}

.find-on-facebook {
  max-width: 400px;
  padding: 1em;
}